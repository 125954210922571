import React from "react";
import Helmet from "react-helmet";
import "react-modal-video/css/modal-video.min.css";
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/font-awesome.min.css";
import "../assets/plugins/dimon-icons/style.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import MessengerCustomerChat from "react-messenger-customer-chat";

const Layout = (props) => {
  return (
    <div>
      <Helmet>
        <title>{props.pageTitle}</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link
          href="/your-path-to-fontawesome/css/all.css"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900&display=swap"
          rel="stylesheet"
        />
        <meta
          name="title"
          content="Molecula Lab - We are a specialized development laboratory in Software, Apps, Web, Design and Digital marketing"
        />
        <meta
          name="description"
          content="Molecula Lab - We are a specialized development laboratory in Software, Apps, Web, Design and Digital marketing. 
We transform technology in molecules
We develop innovative processes based on
engineering, science and design."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.moleculalab.com/" />
        <meta
          property="og:title"
          content="Molecula Lab - We are a specialized development laboratory in Software, Apps, Web, Design and Digital marketing"
        />
        <meta
          property="og:description"
          content="Molecula Lab - We are a specialized development laboratory in Software, Apps, Web, Design and Digital marketing. 
We transform technology in molecules
We develop innovative processes based on
engineering, science and design."
        />
        <meta
          property="og:image"
          content="https://i.postimg.cc/FRH3fLpy/molecules-innovation.jpg"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.moleculalab.com/" />
        <meta
          property="twitter:title"
          content="Molecula Lab - We are a specialized development laboratory in Software, Apps, Web, Design and Digital marketing"
        />
        <meta property="twitter:description" content="" />
        <meta
          property="twitter:image"
          content="https://i.postimg.cc/FRH3fLpy/molecules-innovation.jpg"
        />
      </Helmet>

      <div className="page-wrapper">{props.children}</div>
      <a
        href="https://wa.me/573187679630"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};
export default Layout;
