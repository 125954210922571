import React, { Component } from "react";
import darkLogo from "../assets/images/resources/molecula-logo-blue.png";


export default class Blog extends Component {
  constructor() {
    super();
    this.state = {
      scrollBtn: false
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 70) {
      this.setState({
        scrollBtn: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        scrollBtn: false
      });
    }
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <footer className="site-footer">
          <div className="site-footer__upper">
            <div className="container">
              <div className="row">
                        <div className="col-lg-10 col sm-12">
                            <div className="footer-widget footer-widget__about">
                              <img
                                src={darkLogo}
                                width="220"
                                alt=""
                                className="footer-widget__logo"
                              />
                          
                             <p className="footer-widget__contact">
                              <a href="tel:573187679630"><i class="fa fa-whatsapp"></i> +57 3187679630</a>
                             </p>

                               <p className="footer-widget__contact">
                                 <a href="mailto:needhelp@example.com">
                                <i class="fa fa-envelope"></i> adrenaline@moleculalab.com
                               </a>
                                </p>
                                <p className="footer-widget__contact">
                                 Cali - Colombia <br /> 
                                </p>
                         
                              </div>
                        </div>


                  <div className="footer-widget col-sm-12 col-lg-2 pl-2">
                    <h3 className="footer-widget__title">Services</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <a href="#none">App + Web Development </a>
                      </li>
                      <li>
                        <a href="#none">e-Commerce solutions </a>
                      </li>
                      <li>
                        <a href="#none">Digital Marketing + Innovation </a>
                      </li>
                      <li>
                        <a href="#none">Custom Development</a>
                      </li>
                    </ul>
                  </div>
                

               

                <div className="col-lg-3 mx-auto">
                  <div className="footer-widget">
                    <div className="site-footer__social">
                      <a
                        href="https://www.facebook.com/MoleculaLAB/"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                      >
                        <i className="fa fa-facebook-square"></i>
                      </a>
                      <a
                        href="https://github.com/MoleculaLab"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                      >
                        <i className="fa fa-github"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/molecula-lab/"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/moleculalab/"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="inner-container text-center">
                <p className="site-footer__copy">
                Designed with ♥ by{" "} <a href="https://semanticadigital.com/">Semántica Digital </a>
                Developed by {" "} <a href="https://moleculalab.com/">Molecula LAB </a>
                  &copy; copyright {new Date().getFullYear()} 
                <br/>
                Made with <a href="https://reactjs.org/">ReactJS </a> + <a href="https://www.gatsbyjs.com/">GatsbyJS </a>  Molecules 
                </p>
              </div>
            </div>
          </div>
        </footer>

        <div
          onClick={this.scrollTop}
          onKeyDown={this.scrollTop}
          role="button"
          tabIndex="0"
          className="scroll-to-target scroll-to-top"
          style={{ display: this.state.scrollBtn ? "block" : "none" }}
        >
          <i className="fa fa-angle-up"></i>
        </div>
      </div>
    );
  }
}
